<template>
  <div class="app-container">
    <eHeader :query="query" :dataTypeOptions="dataTypeOptions"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="ID"/>
      <el-table-column prop="dimensionName" label="维度名称"/>
      <el-table-column prop="dataType" label="维度值数据类型"/>
      <el-table-column prop="comment" label="备注"/>
      <el-table-column label="状态">
        <template slot-scope="scope">
          <span>{{ scope.row.status ? '启用':'禁用' }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','SRPERMISSIONDIMENSION_ALL','SRPERMISSIONDIMENSION_EDIT'])" :data="scope.row" :sup_this="sup_this" :dataTypeOptions="dataTypeOptions"/>
          <el-popover
            v-if="checkPermission(['ADMIN','SRPERMISSIONDIMENSION_ALL','SRPERMISSIONDIMENSION_DELETE'])"
            :ref="scope.row.id"
            placement="top"
            width="180">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" type="danger" size="mini">删除</el-button>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { delDPDimension } from '@/api/system/dataper'
import eHeader from '@/components/system/dataper/dpdimension/header'
import edit from '@/components/system/dataper/dpdimension/edit'
export default {
  name:'dpdimension',
  components: { eHeader, edit },
  mixins: [initData],
  data() {
    return {
      delLoading: false, sup_this: this,
      dataTypeOptions: [
        { value: 'long', label: 'long' },
        { value: 'string', label: 'string'},
        { value: 'blob', label: 'blob'}
      ],
    }
  },
  created() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = 'user/crm/srPermissionDimension'
      const sort = 'id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const dimensionName = query.dimensionName
      if (dimensionName !== '' && dimensionName !== null) { this.params['dimensionName'] = dimensionName }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      delDPDimension(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
